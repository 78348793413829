<template>
  <KTCard
    :page="page"
    :isForm="true"
    :isEdit="false"
    @submit="onSubmit"
    :isLoading="ApiIsLoading"
    :cancelTo="parentRoute"
    :readonly="finalizado"
  >
    <template #body>
      <b-row>
        <b-col
          md="6"
          lg="4"
          xl="3"
          v-for="(item, index) in form"
          :key="'ano-' + item.ano"
        >
          <b-card no-body class="p-3 mt-8">
            <p class="font-weight-bold">Ano {{ item.ano }}</p>
            <b-form-group label-size="lg" class="mb-0">
              <template #label>
                Percentual de reajuste <span class="text-danger">*</span>
              </template>
              <template #default>
                <b-input-group append="%">
                  <KTInputNumeric
                    v-model="form[index].percentual_reajuste"
                    :precision="2"
                    :min="0"
                    :max="100"
                    autocomplete="off"
                    :required="!finalizado"
                    :disabled="finalizado"
                  />
                </b-input-group>
              </template>
            </b-form-group>
          </b-card>
        </b-col>
      </b-row>
    </template>
  </KTCard>
</template>

<script>
import ApiMixin from "@/core/mixins/api.mixin.js";
import HelpersMixin from "@/core/mixins/helpers.mixin.js";

export default {
  name: "PipCustoMateriaisForm",
  props: {
    idplano_investimento: {
      required: true
    }
  },
  mixins: [ApiMixin, HelpersMixin],
  data() {
    return {
      page: {
        icon: "la la-cart-plus",
        title: "Custo com Materiais",
        description: `É comum as matérias-primas e embalagens que compõem um produto sofrerem
          reajustes de preço ao longo dos anos e se não forem reajustados, pode comprometer o
          resultado da análise do projeto. Preencha o percentual de reajuste para cada ano da
          análise. Se não houver reajuste a fazer preencha com “0”.`
      },
      form: [],
      anos: 0,
      tipo_plano_investimento: null,
      finalizado: true
    };
  },
  computed: {
    apiResource() {
      return `pip/${this.idplano_investimento}/custo_materiais`;
    },
    manual() {
      return {
        session: "custo_materiais",
        tipo: this.tipo_plano_investimento
      };
    },
    parentRoute() {
      return {
        name: "pip_custo_materiais_list",
        params: { idplano_investimento: this.idplano_investimento }
      };
    }
  },
  methods: {
    getPageData() {
      const requests = [this.Api.GetData()];

      const resolveFuntion = (res) => {
        // Requests index's
        const R_GETDATA = 0;

        this.anos = res[R_GETDATA].anos;
        if (
          res[R_GETDATA].custo_materiais &&
          res[R_GETDATA].custo_materiais.length
        ) {
          this.form = res[R_GETDATA].custo_materiais;
        } else {
          this.form = [];
          for (let i = 2; i <= this.anos; i++) {
            this.form.push({
              ano: i
            });
          }
        }
        this.tipo_plano_investimento = res[R_GETDATA].tipo_plano_investimento;
        this.finalizado = res[R_GETDATA].finalizado;

        this.initManual(this.manual.session, this.manual.tipo);
      };

      this.ApiGetPageData(requests, resolveFuntion);
    },
    onSubmit() {
      this.ApiSubmitAdd(this.form);
    }
  },
  mounted() {
    this.getPageData();
  }
};
</script>
